@charset "UTF-8";
iframe#agreement-frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  border: none;
}

iframe#iframe-tutorial {
  width: 100%;
  height: 600px;
}

@font-face {
  font-family: "HeiTi";
  src: url("../fonts/heiTi.otf");
}
html,
body {
  font-family: Tahoma, Helvetica, "Microsoft Yahei", STXihei, sans-serif;
  margin: 0;
  padding: 0;
  min-width: 1200px;
  background-color: #6d0db1;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}

body.ready .body-wrapper {
  opacity: 1;
}

.hide {
  display: none !important;
}

.wrapper {
  width: 1200px;
  margin: auto;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clearfix {
  display: block;
  clear: both;
}

.parallax-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.inline-input {
  display: inline;
  position: relative;
}

.col-1 {
  width: 10%;
}

.col-2 {
  width: 20%;
}

.col-3 {
  width: 30%;
}

.col-4 {
  width: 40%;
}

.col-5 {
  width: 50%;
}

.col-6 {
  width: 60%;
}

.col-7 {
  width: 70%;
}

.col-8 {
  width: 80%;
}

.col-9 {
  width: 90%;
}

.header {
  background-color: #fff;
  height: 96px;
  width: 100%;
  font-size: 14px;
}
.header > div {
  display: flex;
  align-items: center;
}
.header .logo-wrapper {
  background-size: contain;
  background-repeat: no-repeat;
  width: 150px;
  height: 45px;
  margin-right: auto;
}
.header .logo-wrapper img {
  height: 100%;
}
.header .nav {
  padding-left: 5px;
  display: flex;
  align-items: center;
}
.header .nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}
.header .nav-list .nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 9px;
}
.header .nav-list .nav-item.hide {
  display: none;
}
.header .nav-list .nav-item a {
  text-decoration: none;
  color: #333;
  text-align: center;
}
.header .nav-list .nav-item a.active, .header .nav-list .nav-item a:hover {
  color: #3c77d8;
}
.header .login-form {
  float: right;
  position: relative;
  margin-left: 40px;
}
.header .login-form .login-input {
  height: 96px;
  display: flex;
  align-items: center;
}
.header .login-form .login-input.hide {
  display: none;
}
.header .login-form .login-input > *:not(:last-child) {
  margin-right: 5px;
}
.header .login-form .login-input input {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 2px;
  border: 1px solid #333;
  width: 104px;
  height: 25px;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #000;
}
.header .login-form .login-input input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9e9e9e;
  font-size: 12px;
}
.header .login-form .login-input input::-moz-placeholder {
  /* Firefox 19+ */
  color: #9e9e9e;
  font-size: 12px;
}
.header .login-form .login-input input:-ms-input-placeholder {
  /* IE 10+ */
  color: #9e9e9e;
  font-size: 12px;
}
.header .login-form .login-input input:-moz-placeholder {
  /* Firefox 18- */
  color: #9e9e9e;
  font-size: 12px;
}
.header .login-form .login-input .button-wrapper {
  display: flex;
  align-items: center;
}
.header .login-form .login-input .button {
  min-width: 64px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 0 8px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 12px;
}
.header .login-form .login-input .button:not(:last-child) {
  margin-right: 4px;
}
.header .login-form .login-input .forgot-password {
  display: inline-block;
  position: absolute;
  right: 10px;
  color: #3c77d8;
  text-decoration: none;
  font-size: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.header .login-form .login-input .verification {
  position: absolute;
  right: 4px;
  width: auto;
  height: 19px;
  top: 50%;
  transform: translateY(-50%);
}
.header .login-form .login-input input:focus + .input-label {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) scale(1);
  -ms-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
}
.header .login-form .login-input .input-label {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 10px;
  top: -20px;
  line-height: 12px;
  font-size: 8px;
  color: #fff;
  -webkit-transform: translateY(20px) scale(1.5);
  -ms-transform: translateY(20px) scale(1.5);
  transform: translateY(20px) scale(1.5);
  -webkit-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.header .login-form .login-input .button {
  transition: background-color 200ms ease-in-out;
}
.header .login-form .login-input .button.login {
  background-color: #6fc051;
  color: #fff;
}
.header .login-form .login-input .button.login:hover {
  background-color: #55a637;
}
.header .login-form .login-input .button.trial {
  background-color: #3c9dc4;
  color: #fff;
}
.header .login-form .login-input .button.trial:hover {
  background-color: #2d81a3;
}
.header .login-form .login-input .button.register {
  background-color: #ed1b93;
  color: #fff;
}
.header .login-form .login-input .button.register:hover {
  background-color: #af0a68;
}
.header .login-form .login-support {
  position: absolute;
  bottom: 14px;
  width: 100%;
  color: #fff;
  font-size: 13px;
}
.header .login-form .login-support.login {
  top: 65px;
  width: 400px;
  right: 0;
}
.header .login-form .login-support a {
  color: #fff;
}
.header .login-form.verify .inline-input input {
  width: 100px;
}
.header .login-form.trial .button {
  min-width: initial;
  margin-right: 2px;
}
.header .login-form.trial.verify .inline-input input {
  width: 105px;
}
.header .login-form.trial.verify .button {
  min-width: initial;
}
.header .header-links {
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  width: 400px;
}
.header .header-links > * {
  float: left;
  min-width: 80px;
  text-align: center;
}
.header .header-links a {
  text-decoration: none;
  color: #fff;
}
.header .header-links a.active, .header .header-links a:hover {
  color: #3c77d8;
}
.header .header-links .button {
  float: right;
}

.content {
  position: relative;
  height: calc(100vh - 96px);
  overflow: hidden;
}
.content .swiper-container {
  width: 100%;
  height: 100%;
}
.content .swiper-container .swiper-slide {
  width: 100%;
  height: 100%;
}
.content .swiper-container .swiper-slide .bg {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.content.game {
  position: relative;
  /*min-height: 100vh;*/
  height: auto;
  background: #3026a7;
}
.content.service {
  position: relative;
  /*height: 100vh;*/
  height: auto;
  margin-bottom: 200px;
  -webkit-box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
  box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #370165 0%, #270061 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-linear-gradient(315deg, #370165 0%, #270061 100%);
  background: -o-linear-gradient(315deg, #370165 0%, #270061 100%);
  background: linear-gradient(135deg, #370165 0%, #270061 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#370165", endColorstr="#270061",GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}
.content.mobile {
  position: relative;
  height: auto;
  background: url("../img/password-bg.png") center/cover no-repeat;
  margin-bottom: 200px;
}
.content.mobile .mobile-bg {
  height: 700px;
  background: url(../img/mobile.png) center/auto 100% no-repeat;
  margin-bottom: 250px;
}
.content.mobile .mobile-bg.wdl {
  background-image: url(../img/mobile_wdl.png);
}
.content.mobile .qr-code {
  position: absolute;
  top: 235px;
}
.content.mobile .qr-code.android {
  left: calc(50% + 85px);
}
.content.mobile .qr-code.ios {
  left: calc(50% + 275px);
}
.content.mobile .android-dl,
.content.mobile .ios-dl {
  position: absolute;
  left: calc(50% + 85px);
  top: 424px;
  height: 48px;
  width: 150px;
}
.content.mobile .ios-dl {
  left: calc(50% + 275px);
}
.content.promo {
  position: relative;
  height: auto;
  margin-bottom: 200px;
  -webkit-box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
  box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
  background-image: url(../img/promo-bg.png);
  background-color: #caaf96;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;
}
.content.faq {
  position: relative;
  height: auto;
  margin-bottom: 200px;
  -webkit-box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
  box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
  background-image: url(../img/faq-bg.png);
  background-color: #19baee;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;
}
.content.password {
  position: relative;
  height: auto;
  margin-bottom: 200px;
  -webkit-box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
  box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
  background-image: url(../img/password-bg.png);
  background-color: #19baee;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.content.password .tab-switch ul li:not(:last-child) {
  margin-right: 25px;
}

.phone-wrapper {
  position: relative;
  height: 380px;
  margin-top: 70px;
}
.phone-wrapper .mobile1 {
  background-image: url(../img/mobile1.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  width: 400px;
  height: 600px;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.phone-wrapper .mobile1a {
  background-image: url(../img/mobile1a.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  width: 400px;
  height: 600px;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.phone-wrapper .mobile2 {
  background-image: url(../img/mobile2.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  width: 400px;
  height: 600px;
  left: 15%;
  top: 60px;
}
.phone-wrapper .mobile3 {
  background-image: url(../img/mobile3.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  width: 400px;
  height: 600px;
  right: 15%;
  top: 60px;
}
.phone-wrapper .mobile4 {
  background-image: url(../img/mobile4.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  width: 400px;
  height: 600px;
  left: 3%;
  top: 120px;
}
.phone-wrapper .mobile4a {
  background-image: url(../img/mobile4a.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  width: 400px;
  height: 600px;
  left: 3%;
  top: 120px;
}
.phone-wrapper .mobile5 {
  background-image: url(../img/mobile5.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  width: 400px;
  height: 600px;
  right: 3%;
  top: 120px;
}
.phone-wrapper .mickey {
  background-image: url(../img/mickey.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  width: 250px;
  height: 182px;
  right: 58px;
  top: -40px;
}

.content.service .wrapper, .content.mobile .wrapper, .content.promo .wrapper, .content.faq .wrapper, .content.password .wrapper {
  padding-bottom: 375px;
}
.content .image-header {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.content .image-header > img {
  width: 100px;
  margin-right: 4px;
}
.content .image-header .text h3 {
  margin: 0;
  font-size: 18px;
}
.content .image-header .text p {
  margin: 0;
  font-size: 14px;
}

.image-title {
  background-repeat: no-repeat;
  background-size: auto 80%;
  background-position: center;
  width: 80%;
  margin: 0 auto;
  height: 180px;
  font-size: 0;
  margin-top: 20px;
  position: relative;
}
.image-title:before {
  content: "";
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  background-image: url(../img/flare.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 1;
  -webkit-animation: blink 1s infinite alternate;
  animation: blink 1s infinite alternate;
  top: 30px;
  right: 40%;
}
.image-title:after {
  content: "";
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  background-image: url(../img/flare.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 1;
  -webkit-animation: blink 2s infinite alternate;
  animation: blink 2s infinite alternate;
  top: 15%;
  left: 35%;
}
.image-title.mobile {
  background-image: url(../img/mobile-header.png);
}
.image-title.promo, .image-title.promoa {
  background-image: url(../img/promo-header.png);
}
.image-title.faq {
  background-image: url(../img/faq-header.png);
}
.image-title.password {
  background-image: url(../img/password-header.png);
}
.image-title.register {
  background-image: url(../img/meowmeow.png);
}
.image-title.registera {
  background-image: url(../img/register-headera.png);
}

.content-title {
  font-size: 20px;
  letter-spacing: 20px;
  margin: 70px 0 10px;
  text-align: center;
  color: #fff;
  font-weight: normal;
  text-indent: 20px;
}

.content-title-sub {
  color: #fff;
  font-size: 30px;
  letter-spacing: 20px;
  text-indent: 20px;
  margin: 10px auto;
  text-align: center;
  position: relative;
  display: block;
  width: 400px;
  /*font-family: "HeiTi", Arial, sans-serif;*/
  /*font-weight: normal;*/
  /*font-style: normal;*/
}

.content-title.dark {
  color: #727272;
}

.content-title-sub.dark {
  color: #727272;
}
.content-title-sub.dark .line {
  border-bottom: 4px solid #727272;
}
.content-title-sub .line {
  /*position: absolute;*/
  width: 80px;
  margin-top: 20px;
  border-bottom: 4px solid #fff;
}
.content-title-sub .line.left {
  /*left: calc(50% - 100px);*/
  /*transform: translateX(-100px);*/
}
.content-title-sub .line.right {
  /*right: calc(50% - 100px);*/
  /*transform: translateX(100px);*/
}

.banner1 .bg {
  background-image: url(../img/banner/banner_1_bg.png);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.banner1 .title {
  background-image: url(../img/banner/banner_1_title.png);
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  width: 40%;
  height: 400px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 100px;
  -webkit-animation: bounceInDown 1s linear forwards;
  animation: bounceInDown 1s linear forwards;
}
.banner1 .character {
  background-image: url(../img/banner/banner_1_character.png);
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  width: 63%;
  height: 525px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 30vh;
  -webkit-animation: zoomInUp 2s linear forwards;
  animation: zoomInUp 2s linear forwards;
}
.banner1 .deco {
  background-image: url(../img/banner/banner_1_deco.png);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-animation: zoomInAlt 5s linear forwards;
  animation: zoomInAlt 5s linear forwards;
}
.banner1 .button.go {
  cursor: pointer;
  background-image: url(../img/banner/banner_1_btn.png);
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  width: 20%;
  height: 90px;
  position: absolute;
  margin: auto;
  top: 56vh;
  left: 0;
  right: 0;
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  transition: -webkit-transform 200ms ease-in-out;
  -o-transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  -webkit-animation: pulse 1s infinite alternate;
  animation: pulse 1s infinite alternate;
}
.banner1 .button.go:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}

@media (max-height: 700px) {
  .banner1 .title {
    top: 40px;
  }
  .banner1 .character {
    width: 50%;
    height: 280px;
    top: 135px;
  }
  .banner1 .button.go {
    top: 280px;
    width: 180px;
  }
}
@media (max-height: 560px) {
  .banner1 .title {
    top: 4px;
  }
  .banner1 .character {
    width: 40%;
    height: 280px;
    top: 128px;
  }
  .banner1 .button.go {
    top: 225px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .content {
    height: 550px;
  }
  .banner1 .title {
    top: 40px;
  }
  .banner1 .character {
    width: 50%;
    height: 280px;
    top: 135px;
  }
  .banner1 .button.go {
    top: 320px;
  }
}
.banner2 .bg {
  background-image: url(../img/banner/banner_2_bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.banner2 .title {
  background-image: url(../img/banner/banner_2_title.png);
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  width: 35%;
  height: 550px;
  position: absolute;
  margin: auto;
  left: 20%;
  top: 10%;
  -webkit-animation: bounceInLeft 1s linear forwards;
  animation: bounceInLeft 1s linear forwards;
}
.banner2 .titlea {
  background-image: url(../img/banner/banner_2_titlea.png);
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  width: 35%;
  height: 550px;
  position: absolute;
  margin: auto;
  left: 20%;
  top: 10%;
  -webkit-animation: bounceInLeft 1s linear forwards;
  animation: bounceInLeft 1s linear forwards;
}
.banner2 .character {
  background-image: url(../img/banner/banner_2_character.png);
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  width: 30%;
  height: 70%;
  position: absolute;
  margin: auto;
  right: 10%;
  top: 8%;
  -webkit-animation: zoomInUp 2s linear forwards;
  animation: zoomInUp 2s linear forwards;
}
.banner2 .deco {
  background-image: url(../img/banner/banner_2_deco.png);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-animation: zoomInAlt 5s linear forwards;
  animation: zoomInAlt 5s linear forwards;
}
.banner2 .button.go:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}

.banner3 .bg {
  background-image: url(https://imgsg99.com/dsn3/homebanner5.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.banner-addon {
  height: 90px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 45px;
  z-index: 2;
  -webkit-animation: flipInX 1s linear forwards;
  animation: flipInX 1s linear forwards;
  color: #fff;
}
.banner-addon .addon-title {
  position: absolute;
  margin: 0;
  font-size: 14px;
  color: #fff;
  top: -66px;
  left: 40px;
}
.banner-addon .info p {
  display: inline;
  white-space: nowrap;
  color: #fff;
  margin: 0 20px;
}
.banner-addon .info a {
  text-decoration: none;
  color: #fff;
}
.banner-addon .wrapper > div.promo {
  position: relative;
  bottom: 10px;
  height: 90px;
  background: #fff;
}
.banner-addon .wrapper > div.info {
  padding-left: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
.banner-addon .wrapper > div.info:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background-image: url(../img/icon/message.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  left: 7px;
  top: -2px;
}
.banner-addon .wrapper .col-3 {
  width: calc(30% - 20px);
}
.banner-addon .wrapper > div.promo {
  background: linear-gradient(to bottom, #9f7b2c 0%, #be9331 100%);
  border: 5px solid #fff;
  box-sizing: border-box;
  cursor: default;
}
.banner-addon .wrapper > div.promo:nth-child(1) {
  margin-right: 20px;
}
.banner-addon .more-addon {
  position: relative;
  height: 100%;
}
.banner-addon .more-addon .swiper-container-phone {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  bottom: 10px;
  left: 0;
}
.banner-addon .more-addon .swiper-container-phone img {
  position: absolute;
  width: 80%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}
.banner-addon .more-addon > img {
  position: absolute;
  right: 45px;
  bottom: 5px;
  z-index: 2;
}
.banner-addon .more-addon .more {
  text-decoration: none;
  color: #fffa54;
  position: absolute;
  top: 10px;
  right: 30%;
  z-index: 3;
}
.banner-addon .addon-item {
  float: left;
  width: 33%;
  height: 100%;
  color: #fff;
  position: relative;
  font-size: 13px;
  padding: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.banner-addon .addon-item h4 {
  margin: 10px 0;
  color: #fffa54;
}
.banner-addon .addon-item p {
  margin: 0;
}
.banner-addon .addon-item img {
  padding: 5px 10px;
  margin: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.games-container {
  margin: 50px auto;
}
.games-container:after {
  content: "";
  display: table;
  clear: both;
}
.games-container .wrapper > div {
  position: relative;
}
.games-container .wrapper .right {
  padding-top: 40px;
}
.games-container .deco {
  background-image: url(../img/deco.png);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-animation: zoomInAlt 5s linear forwards;
  animation: zoomInAlt 5s linear forwards;
}
.games-container .game-bg {
  width: 500px;
  height: 540px;
}
.games-container .game-bg img {
  width: 100%;
}
.games-container .game-bg .glare {
  display: block;
  position: absolute;
  width: 120px;
  height: 120px;
  background-image: url(../img/flare.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 1;
}
.games-container .game-bg .glare:nth-child(1) {
  top: 163px;
  right: 19px;
  -webkit-animation: blink 3s infinite alternate;
  animation: blink 3s infinite alternate;
}
.games-container .game-bg .glare:nth-child(2) {
  top: -40px;
  right: 75px;
  -webkit-animation: blink 4s infinite alternate;
  animation: blink 4s infinite alternate;
}
.games-container .game-bg .glare:nth-child(3) {
  top: 352px;
  left: 87px;
  -webkit-animation: blink 2s infinite alternate;
  animation: blink 2s infinite alternate;
}
.games-container .game-bg .glare:nth-child(4) {
  top: 394px;
  left: 132px;
  -webkit-animation: blink 1s infinite alternate;
  animation: blink 1s infinite alternate;
}
.games-container .game-title {
  position: absolute;
  width: 335px;
  height: 300px;
  top: 0;
  left: -20px;
}
.games-container .current-game {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 115px;
  left: 80px;
}
.games-container .current-game div {
  width: 100%;
  height: 100%;
  background-image: url(../img/game-sprite-x4.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  -webkit-transition: background-position 600ms ease-in-out, -webkit-filter 200ms ease-in-out;
  transition: background-position 600ms ease-in-out, -webkit-filter 200ms ease-in-out;
  -o-transition: background-position 600ms ease-in-out, filter 200ms ease-in-out;
  transition: background-position 600ms ease-in-out, filter 200ms ease-in-out;
  transition: background-position 600ms ease-in-out, filter 200ms ease-in-out, -webkit-filter 200ms ease-in-out;
}
.games-container .current-game div.blur {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
.games-container .current-game div.pk10 {
  background-position: 0 0;
}
.games-container .current-game div.bjl {
  background-position: 0 -400px;
}
.games-container .current-game div.bjpk10 {
  background-position: 0 -800px;
}
.games-container .current-game div.jsssc {
  background-position: 0 -1200px;
}
.games-container .current-game div.cqssc {
  background-position: 0 -1600px;
}
.games-container .current-game div.auluck5 {
  background-position: 0 -2000px;
}
.games-container .current-game div.hk6 {
  background-position: 0 -2400px;
}
.games-container .current-game div.k3 {
  background-position: 0 -2800px;
}
.games-container .current-game div.gxklsf {
  background-position: 0 -3200px;
}
.games-container .current-game div.gdklsf {
  background-position: 0 -3600px;
}
.games-container .current-game div.gd11x5 {
  background-position: 0 -4000px;
}
.games-container .current-game div.tcpl3 {
  background-position: 0 -4400px;
}
.games-container .current-game div.kl8 {
  background-position: 0 -4800px;
}
.games-container .current-game div.xjssc {
  background-position: 0 -5200px;
}
.games-container .current-game div.tjssc {
  background-position: 0 -5600px;
}
.games-container .current-game div.auluck10 {
  background-position: 0 -6000px;
}
.games-container .current-game div.auluck20 {
  background-position: 0 -6400px;
}
.games-container .current-game div.pcegg {
  background-position: 0 -6800px;
}
.games-container .current-game div.f3d {
  background-position: 0 -7200px;
}
.games-container .current-game div.auluck8 {
  background-position: 0 -7600px;
}
.games-container .current-game div.xync {
  background-position: 0 -8000px;
}
.games-container .game-title .game-start {
  width: 100%;
  height: 100%;
  background-image: url(../img/game-start.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.games-container .game-title .game-end {
  position: absolute;
  left: 68px;
  top: 78px;
  width: 80%;
  height: 80%;
  background-image: url(../img/game-end.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.games-container .button.go {
  width: 110px;
  height: 135px;
  background-image: url(../img/game-btn.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 40px;
  right: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.games-container .button.go .play.icon {
  width: 100%;
  height: 100%;
  background-image: url(../img/game-btn-play.png);
  background-position: 55% center;
  background-repeat: no-repeat;
  -webkit-transform: translateZ(20px);
  transform: translateZ(20px);
}
.games-container .button.go:after {
  content: "";
  width: 135px;
  height: 135px;
  background-image: url(../img/game-btn-glare.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -30px;
  right: -60px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(20px);
  transform: translateZ(20px);
  -webkit-animation: blink 5s infinite alternate;
  animation: blink 5s infinite alternate;
}
.games-container .button.go a {
  font-size: 0;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transform: translateZ(40px);
  transform: translateZ(40px);
}
.games-container .button.go a.hide {
  display: none;
}
.games-container .game-list {
  width: 600px;
  height: auto;
  position: relative;
}
.games-container .game-list.small {
  width: 450px;
  margin: auto;
}
.games-container .more-games {
  position: absolute;
  right: 140px;
  top: 0;
  text-decoration: none;
  color: #fffa54;
}
.games-container .more-games .swiper-container {
  width: 600px;
  height: 450px;
}
.games-container .game-list .game-item {
  float: left;
  height: 150px;
  width: 150px;
  background-image: url(../img/blue-ball.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% 80%;
  position: relative;
  transition: all 200ms ease-in-out;
}
.games-container .game-list .game-item:hover {
  transform: scale(1.1);
}
.games-container .game-list .game-item div {
  background-image: url(../img/game-sprite.png);
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
}
.games-container .game-list .game-item div.pk10 {
  background-position: 0 0;
}
.games-container .game-list .game-item div.bjl {
  background-position: 0 -150px;
}
.games-container .game-list .game-item div.bjpk10 {
  background-position: 0 -300px;
}
.games-container .game-list .game-item div.jsssc {
  background-position: 0 -450px;
}
.games-container .game-list .game-item div.cqssc {
  background-position: 0 -600px;
}
.games-container .game-list .game-item div.auluck5 {
  background-position: 0 -750px;
}
.games-container .game-list .game-item div.hk6 {
  background-position: 0 -900px;
}
.games-container .game-list .game-item div.k3 {
  background-position: 0 -1050px;
}
.games-container .game-list .game-item div.gxklsf {
  background-position: 0 -1200px;
}
.games-container .game-list .game-item div.gdklsf {
  background-position: 0 -1350px;
}
.games-container .game-list .game-item div.gd11x5 {
  background-position: 0 -1500px;
}
.games-container .game-list .game-item div.tcpl3 {
  background-position: 0 -1650px;
}
.games-container .game-list .game-item div.kl8 {
  background-position: 0 -1800px;
}
.games-container .game-list .game-item div.xjssc {
  background-position: 0 -1950px;
}
.games-container .game-list .game-item div.tjssc {
  background-position: 0 -2100px;
}
.games-container .game-list .game-item div.auluck10 {
  background-position: 0 -2250px;
}
.games-container .game-list .game-item div.auluck20 {
  background-position: 0 -2400px;
}
.games-container .game-list .game-item div.pcegg {
  background-position: 0 -2550px;
}
.games-container .game-list .game-item div.f3d {
  background-position: 0 -2700px;
}
.games-container .game-list .game-item div.auluck8 {
  background-position: 0 -2850px;
}
.games-container .game-list .game-item div.xync {
  background-position: 0 -3000px;
}
.games-container .game-list .game-item a {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}
.games-container .game-list .game-item .glare {
  display: block;
  position: absolute;
  width: 90px;
  height: 90px;
  background-image: url(../img/flare3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-animation: blink 1s infinite alternate;
  animation: blink 1s infinite alternate;
  opacity: 0.9;
  z-index: -1;
}

.service {
  list-style: none;
  margin: 0;
  padding: 0;
}
.service.mobile {
  margin: 20px 0;
}
.service .service-item {
  display: inline-block;
  width: 31.6666666667%;
  text-align: center;
  color: #fff;
}
.service.mobile .service-item {
  font-style: italic;
  font-size: 21px;
  position: relative;
  text-align: left;
}
.service.mobile .service-item .coins {
  position: absolute;
  display: block;
  width: 60px;
  height: 60px;
  top: -18px;
  left: 0;
  background-image: url(../img/coin.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.service.mobile .service-item .coins:before {
  content: "";
  position: absolute;
  width: 80px;
  height: 80px;
  background-image: url(../img/flare.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.service.mobile .service-item:nth-child(1) .coins:before {
  top: -25px;
  right: -25px;
  -webkit-animation: blink 1s infinite alternate;
  animation: blink 1s infinite alternate;
}
.service.mobile .service-item:nth-child(2) .coins:before {
  top: -15px;
  right: -25px;
  -webkit-animation: blink 2s infinite alternate;
  animation: blink 2s infinite alternate;
}
.service.mobile .service-item:nth-child(3) .coins:before {
  top: -10px;
  right: -25px;
  -webkit-animation: blink 3s infinite alternate;
  animation: blink 3s infinite alternate;
}
.service.mobile .service-item h4 {
  font-size: 20px;
  margin: 0;
  padding-left: 60px;
}
.service .service-item h3 {
  color: #ffd34c;
  font-size: 45px;
  margin: 0;
  font-family: "HeiTi", Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 4px;
}
.service .service-item h3 span {
  font-weight: bold;
}
.service .service-item .deposit {
  height: 256px;
  background-image: url(../img/icon/service1.png);
  background-repeat: no-repeat;
  background-position: center;
}
.service .service-item .withdraw {
  height: 256px;
  background-image: url(../img/icon/service2.png);
  background-repeat: no-repeat;
  background-position: center;
}
.service .service-item .bank {
  height: 256px;
  background-image: url(../img/icon/service3.png);
  background-repeat: no-repeat;
  background-position: center;
}

.service-footer {
  position: absolute;
  bottom: -40px;
  height: 345px;
  width: 100%;
  background-image: url(../img/wave.png);
  background-size: 100% auto;
  -webkit-animation: loopfooter 120s linear infinite;
  animation: loopfooter 120s linear infinite;
}

.service-wrapper {
  color: #fff;
  height: 66px;
  margin-top: 60px;
}
.service-wrapper .desc h3,
.service-wrapper .desc h4 {
  margin: 0;
  font-weight: normal;
}
.service-wrapper .desc h3 {
  font-size: 30px;
  margin-bottom: 10px;
}
.service-wrapper .desc h4 {
  font-size: 17px;
}
.service-wrapper .lion {
  background-image: url(../img/icon/service4.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 66px;
}
.service-wrapper .sun {
  background-image: url(../img/icon/service5.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 66px;
}

.partner {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partner li {
  flex-grow: 1;
  display: inline-block;
  width: 23.75%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: auto auto;
  background-position: center;
}
.partner li.suncity {
  background-image: url(http://404ident331.com/);
}
.partner li.alipay {
  background-image: url(../img/icon/alipay-icon.png);
}
.partner li.wechatpay {
  background-image: url(../img/icon/wechatpay-icon.png);
}
.partner li.unionpay {
  background-image: url(../img/icon/unionpay-icon.png);
}

.tab-container {
  width: 100%;
  height: auto;
  min-height: 400px;
  background-color: #fff;
  box-shadow: 0 0 18px #1c9cf0;
  position: relative;
}
.tab-container.left-panel {
  background-color: #fff;
  margin: auto;
  display: flex;
  align-items: flex-start;
}
.tab-container.left-panel:after {
  content: "";
  display: table;
  clear: both;
}
.tab-container.left-panel .tab-switch {
  display: inline-block;
  width: 250px;
  vertical-align: top;
  z-index: 2;
}
.tab-container.top-panel {
  background-color: #fff;
  padding: 30px;
}
.tab-container.top-panel:after {
  content: "";
  display: table;
  clear: both;
}
.tab-container.top-panel .tab-switch {
  display: block;
  margin-bottom: 25px;
}
.tab-container.left-panel .tab-switch ul {
  display: block;
}
.tab-container.left-panel .tab-switch ul li {
  display: block;
  height: 70px;
  background: #3c77d8;
  -webkit-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  border-bottom: 1px solid #fff;
}
.tab-container.left-panel .tab-switch ul li.active {
  background: #fff;
  z-index: 99;
}
.tab-container.left-panel .tab-switch ul li.active a {
  color: #3c77d8;
}
.tab-container.top-panel .tab-switch ul li {
  display: block;
  transition: all 200ms ease-in-out;
}
.tab-container .tab-switch ul {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tab-container .tab-switch ul li {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 33%;
  position: relative;
  background: #3c77d8;
}
.tab-container .tab-switch ul li.active {
  font-weight: bold;
}
.tab-container .tab-switch ul li a,
.tab-container .tab-switch ul li span {
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-container .tab-content {
  padding: 20px 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.tab-container .tab-content p {
  line-height: 1.35rem;
}
.tab-container.left-panel .tab-content {
  display: inline-block;
  width: calc(100% - 250px);
  vertical-align: top;
  z-index: 1;
  color: #333;
  border-left: 1px solid #3c77d8;
}
.tab-container.top-panel .tab-content {
  background-color: #4582dd;
  z-index: 1;
  color: #fff;
  padding: 10px 30px;
  margin: auto;
}
.tab-container .tab-content .sub-content {
  display: none;
}
.tab-container .tab-content .sub-content:not(:last-child) {
  margin-bottom: 20px;
}
.tab-container .tab-content .sub-content.active {
  display: block;
}
.tab-container .tab-content .sub-content-item {
  height: auto;
  width: 100%;
}
.tab-container .tab-content .sub-content-item .sub-content-item-header {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}
.tab-container .tab-content .sub-content-item .sub-content-item-header img {
  background-color: #fff;
  width: 100%;
}
.tab-container .tab-content .sub-content-item .sub-content-item-content {
  right: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  display: none;
}
.tab-container .tab-content .sub-content-item:after {
  content: "";
  display: table;
  clear: both;
}

.footer {
  height: 200px;
  width: 100%;
  z-index: -1;
  position: fixed;
  bottom: 0;
  background-color: #6d0db1;
  color: #fff;
  text-align: center;
}
.footer .first-footer a {
  display: inline-block;
  padding: 0 20px;
  text-decoration: none;
  color: #fff;
  line-height: 100px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-transition: text-shadow 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  transition: text-shadow 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  -o-transition: transform 200ms ease-in-out, text-shadow 200ms ease-in-out;
  transition: transform 200ms ease-in-out, text-shadow 200ms ease-in-out;
  transition: transform 200ms ease-in-out, text-shadow 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  position: relative;
}
.footer .first-footer a:hover {
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
  text-shadow: 0 6px 7px rgba(0, 0, 0, 0.2);
}
.footer .second-footer {
  position: absolute;
  width: 100%;
  height: 100px;
  background-color: #210f27;
  bottom: 0;
  left: 0;
  right: 0;
  color: #707070;
}
.footer .second-footer p {
  margin: 40px auto;
}

.form-wrapper {
  padding: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.sticky-nav {
  position: fixed;
  right: 10px;
  top: 55%;
  width: 100px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
  -webkit-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
@media screen and (max-width: 1439px) {
  .sticky-nav {
    right: 0;
    width: 80px;
  }
}
.sticky-nav .red-packet {
  background-image: url(../img/sticky-nav-bg.png);
  height: 110px;
  width: 100px;
  border-radius: 7px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 5px 14px rgba(55, 55, 55, 0.6);
  box-shadow: 0 5px 14px rgba(55, 55, 55, 0.6);
  position: relative;
}
.sticky-nav .red-packet a {
  font-size: 0;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../img/icon/sticky-nav-icons/red-packet.png);
}
.sticky-nav .sticky-nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sticky-nav .sticky-nav-list li {
  width: 50px;
  height: 50px;
  background: linear-gradient(to right bottom, #3100ff, #7600aa);
  border-radius: 7px;
  margin: auto;
  margin-top: 5px;
  -webkit-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  position: relative;
  -webkit-perspective: 100px;
  perspective: 100px;
}
.sticky-nav .sticky-nav-list .sticky-hidden-content {
  position: absolute;
  right: 60px;
  top: -15px;
  -webkit-transition: opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
  transition: opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
  -o-transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  opacity: 0;
  /*  -webkit-box-shadow: 0 0 10px rgba(55,55,55,0.4);
            box-shadow: 0 0 10px rgba(55,55,55,0.4);*/
}
.sticky-nav .sticky-nav-list .sticky-hidden-content p {
  text-align: center;
}
.sticky-nav .sticky-nav-list .sticky-hidden.kefu {
  position: absolute;
  width: 200px;
  height: 280px;
  right: 60px;
  top: -15px;
  color: #424242;
  background-color: #fff;
  border-radius: 7px;
  -webkit-transition: opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
  transition: opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
  -o-transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  opacity: 0;
  -webkit-box-shadow: 0 0 10px rgba(55, 55, 55, 0.4);
  box-shadow: 0 0 10px rgba(55, 55, 55, 0.4);
}
.sticky-nav .sticky-nav-list .sticky-hidden.kefu.show {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}
.sticky-nav .sticky-nav-list .sticky-hidden.kefu button {
  display: block;
  width: 90%;
  margin: 10px auto;
  height: 40px;
  background: #ffd536 url(../img/sticky-nav-bg.png) center;
  color: #424242;
  outline: 0;
  border: 0;
  border-radius: 7px;
  font-size: 17px;
  cursor: pointer;
  position: relative;
  padding-left: 15px;
}
.sticky-nav .sticky-nav-list .sticky-hidden.kefu button:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/sticky-nav-icons/customer-service.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.sticky-nav .sticky-nav-list .sticky-hidden.kefu button:hover {
  color: #fff;
  background: linear-gradient(to right bottom, #2d00e9, #630090);
}
.sticky-nav .sticky-nav-list .sticky-hidden.kefu .kefu-mobile-title {
  border-top: 1px solid #ccc;
  text-align: center;
  margin: 12px;
  padding-top: 15px;
  position: relative;
  font-size: 16px;
}
.sticky-nav .sticky-nav-list .sticky-hidden.kefu .kefu-mobile-title:before {
  content: "或者";
  position: absolute;
  left: 50%;
  top: -8px;
  font-size: 16px;
  line-height: 16px;
  background-color: #fff;
  padding: 0 10px;
  transform: translateX(-50%);
  color: #aaa;
}
.sticky-nav .sticky-nav-list .sticky-hidden.kefu #mobile-qr {
  width: 150px;
  height: 150px;
  margin: auto;
}
.sticky-nav .sticky-nav-list .sticky-hidden.kefu #mobile-qr img {
  width: 100%;
  height: 100%;
}
.sticky-nav .sticky-nav-list .sticky-hidden-content.download-instruction {
  background-color: #fff;
  width: 180px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #000;
  border-radius: 6px;
  top: 0;
  box-shadow: 0 0 7px rgba(55, 55, 55, 0.5);
}
.sticky-nav .sticky-nav-list .sticky-hidden-content.download-instruction:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 16px;
  height: 16px;
  right: -5px;
  background-color: #fff;
}
.sticky-nav .sticky-nav-list li a {
  font-size: 0;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
}
.sticky-nav .sticky-nav-list li.nav a {
  background-image: url(../img/icon/sticky-nav-icons/nav.png);
  background-size: 83%;
}
.sticky-nav .sticky-nav-list li.qr a {
  background-image: url(../img/icon/sticky-nav-icons/qr.png);
  background-size: 70%;
}
.sticky-nav .sticky-nav-list li.kefuapp a {
  background-image: url(../img/icon/sticky-nav-icons/kefuapp.png);
  background-size: contain;
}
.sticky-nav .sticky-nav-list li.qq a {
  background-image: url(../img/icon/sticky-nav-icons/qq.png);
  background-size: 66%;
}
.sticky-nav .sticky-nav-list li.wechat a {
  background-image: url(../img/icon/sticky-nav-icons/wechat.png);
  background-size: 66%;
}
.sticky-nav .sticky-nav-list li.cs a {
  background-image: url(../img/icon/sticky-nav-icons/customer-service.png);
  background-size: 90%;
}
.sticky-nav .sticky-nav-list li.live-chat a {
  background-image: url(../img/icon/sticky-nav-icons/livechat.png);
  background-size: 65%;
}
.sticky-nav .sticky-nav-list li.download a {
  background-image: url(../img/icon/sticky-nav-icons/download.png);
}
.sticky-nav .sticky-nav-list li.download-mac a {
  background-image: url(../img/icon/sticky-nav-icons/mac_dl.png);
  background-size: 60%;
}
.sticky-nav .sticky-nav-list li.download-windows a {
  background-image: url(../img/icon/sticky-nav-icons/windows_dl.png);
  background-size: 60%;
}
.sticky-nav .sticky-nav-list li.morse a {
  background-image: url(../img/icon/sticky-nav-icons/icon-morse-dark.svg);
}
.sticky-nav .sticky-nav-list li.back-to-top {
  opacity: 0;
}
.sticky-nav .sticky-nav-list li.back-to-top a {
  background-image: url(../img/icon/sticky-nav-icons/top.png);
}
.sticky-nav .sticky-nav-list li:hover .sticky-hidden-content {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

.form-wrapper .field-set:not(:last-child) {
  margin-bottom: 10px;
}
.form-wrapper .field-set > * {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
}
.form-wrapper .field-set .field-label {
  color: #fff;
  width: 100px;
  font-size: 14px;
}
.form-wrapper .field-set .field-input {
  color: #999;
  width: 400px;
  height: 40px;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
}
.form-wrapper .field-set .verification {
  width: 100px;
  margin-left: 20px;
  background-color: #fff;
}
.form-wrapper .field-set .field-info {
  font-size: 12px;
  width: 265px;
  display: inline-flex;
  align-items: center;
  line-height: initial;
  padding-left: 1.5em;
  text-indent: -1em;
  box-sizing: border-box;
}
.form-wrapper .field-set .field-input.small {
  width: 275px;
}
.form-wrapper .field-set .field-button {
  margin-left: 105px;
  width: 400px;
  outline: none;
  border: 0;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  transition: -webkit-transform 200ms ease-in-out;
  -o-transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  background: #df4949;
}
.form-wrapper .field-set .field-button:active {
  transform: translateY(5px);
}
.form-wrapper .form-desc {
  text-align: left;
  color: #fff;
  margin: 50px 0 0;
}

@-webkit-keyframes loopfooter {
  from {
    background-position: 1920px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes loopfooter {
  from {
    background-position: 1920px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(0.3) rotate(0);
    transform: scale(0.3) rotate(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1) rotate(40deg);
    transform: scale(1) rotate(40deg);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: scale(0.2) rotate(80deg);
    transform: scale(0.2) rotate(80deg);
  }
}
@keyframes blink {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(0.3) rotate(0);
    transform: scale(0.3) rotate(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1) rotate(40deg);
    transform: scale(1) rotate(40deg);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: scale(0.2) rotate(80deg);
    transform: scale(0.2) rotate(80deg);
  }
}
/*for kefu popup*/
body.kefu-popup {
  margin: 0;
  padding: 0;
  background-color: #329fd9;
}

.kefu-popup iframe {
  width: 100%;
  height: 100%;
  outline: none;
  border: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
}
.kefu-popup .blur {
  filter: blur(20px);
}
.kefu-popup .close {
  position: fixed;
  background-color: #329fd9;
  width: 100px;
  height: 30px;
  top: 0;
  right: 0;
  outline: none;
  border: 0;
  color: #fff;
  cursor: pointer;
  animation-delay: 1s;
  text-align: right;
  z-index: 999;
}
.kefu-popup .close:hover {
  color: yellow;
}
.kefu-popup .close.no-font {
  font-size: 0;
}
.kefu-popup .footer {
  position: fixed;
  width: 200px;
  height: 30px;
  background-color: #329fd9;
  bottom: 0;
  right: 0;
  animation-delay: 1s;
  z-index: 999;
}
.kefu-popup .instruction {
  display: none;
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  width: 80%;
  height: 50px;
  margin: auto;
}
.kefu-popup .instruction.hidden {
  display: none !important;
}
.kefu-popup .instruction img {
  width: 100%;
  height: auto;
}
.kefu-popup .instruction .close-instruction {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  height: 100%;
}
.kefu-popup .overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(55, 55, 55, 0.5);
  z-index: 999;
}
.kefu-popup .image-container {
  display: none;
  background: url(../img/temp.png) top no-repeat;
  width: 80%;
  height: 500px;
  margin: 40px auto;
  background-size: contain;
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  z-index: 1000;
}
.kefu-popup .user-agent {
  position: fixed;
  bottom: 100px;
  width: 100%;
  height: 100px;
  left: 0;
}

@media all and (display-mode: standalone) {
  .kefu-popup .instruction {
    display: none !important;
  }
}
.appqr-wrapper {
  width: 145px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding-bottom: 10px;
}
.appqr-wrapper .appqrcode {
  width: 100%;
  box-sizing: border-box;
}
.appqr-wrapper .appqrcode .qr-code,
.appqr-wrapper .appqrcode #qr-code {
  width: 110px;
  height: 110px;
  background-color: #fff;
  box-sizing: border-box;
  margin: 10px auto 4px;
}
.appqr-wrapper.kefuapp {
  text-align: center;
  font-size: 0.9rem;
  word-break: break-word;
}

.appqr {
  position: relative;
  width: 100%;
  height: 100%;
}
.appqr img {
  background-color: #fff;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  top: 0;
  position: absolute;
  left: 0;
}

.appqr-wrapper .text {
  color: #ffbf2f;
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  font-style: italic;
}

.appdlurl {
  color: #7e17b3;
  text-align: center;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.faq .relative {
  position: relative;
}
.faq .applogo {
  position: absolute;
  border-radius: 6px;
  padding: 2px;
}
.faq .applogo.logo1 {
  left: 12px;
  top: 20px;
  height: 36px;
  width: 36px;
}
.faq .applogo.logo2 {
  left: 500px;
  top: 142px;
  height: 17px;
  width: 17px;
}
.faq .applogo.logo3 {
  left: 14px;
  top: 25px;
  height: 23px;
  width: 23px;
}
.faq .applogo.logo4 {
  left: 505px;
  top: 25px;
  height: 23px;
  width: 23px;
}
.faq .webname {
  position: absolute;
  color: #000;
}
.faq .webname.name1 {
  color: #6a6a6a;
  font-size: 0.7rem;
  left: 12px;
  top: 60px;
}
.faq .webname.name2 {
  font-size: 0.8rem;
  left: 525px;
  top: 144px;
}
.faq .webname.name3 {
  left: 44px;
  top: 28px;
}
.faq .webname.name4 {
  left: 535px;
  top: 28px;
}