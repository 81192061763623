@import "./../../common/styles";

@font-face {
  font-family: "HeiTi";
  src: url("../fonts/heiTi.otf");
}

html,
body {
  font-family: Tahoma, Helvetica, "Microsoft Yahei", STXihei, sans-serif;
  margin: 0;
  padding: 0;
  min-width: 1200px;
  background-color: #6d0db1;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}
body.ready .body-wrapper {
  opacity: 1;
}

.hide {
  display: none !important;
}

.wrapper {
  width: 1200px;
  margin: auto;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clearfix {
  display: block;
  clear: both;
}

.parallax-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.inline-input {
  display: inline;
  position: relative;
}

.col-1 {
  width: 10%;
}

.col-2 {
  width: 20%;
}

.col-3 {
  width: 30%;
}

.col-4 {
  width: 40%;
}

.col-5 {
  width: 50%;
}

.col-6 {
  width: 60%;
}

.col-7 {
  width: 70%;
}

.col-8 {
  width: 80%;
}

.col-9 {
  width: 90%;
}

.header {
  background-color: #fff;
  height: 96px;
  width: 100%;
  font-size: 14px;
  > div {
    display: flex;
    align-items: center;
  }
  .logo-wrapper {
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 45px;
    margin-right: auto;
    img {
      height: 100%;
    }
  }
  .nav {
    padding-left: 5px;
    display: flex;
    align-items: center;
  }
  .nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 9px;
      &.hide {
        display: none;
      }
      a {
        text-decoration: none;
        color: #333;
        text-align: center;
        &.active,
        &:hover {
          color: #3c77d8;
        }
      }
    }
  }
  .login-form {
    float: right;
    position: relative;
    margin-left: 40px;
    .login-input {
      height: 96px;
      display: flex;
      align-items: center;
      &.hide {
        display: none;
      }
      > *:not(:last-child) {
        margin-right: 5px;
      }
      input {
        background-color: rgba(255, 255, 255, 0);
        border-radius: 2px;
        border: 1px solid #333;
        width: 104px;
        height: 25px;
        padding: 0 10px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #000;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #9e9e9e;
          font-size: 12px;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #9e9e9e;
          font-size: 12px;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #9e9e9e;
          font-size: 12px;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #9e9e9e;
          font-size: 12px;
        }
      }
      .button-wrapper {
        display: flex;
        align-items: center;
      }
      .button {
        min-width: 64px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 0;
        outline: none;
        cursor: pointer;
        padding: 0 8px;
        box-sizing: border-box;
        text-decoration: none;
        font-size: 12px;
        &:not(:last-child) {
          margin-right: 4px;
        }
      }
      .forgot-password {
        display: inline-block;
        position: absolute;
        right: 10px;
        color: #3c77d8;
        text-decoration: none;
        font-size: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
      .verification {
        position: absolute;
        right: 4px;
        width: auto;
        height: 19px;
        top: 50%;
        transform: translateY(-50%);
      }
      input:focus + .input-label {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0) scale(1);
        -ms-transform: translateY(0) scale(1);
        transform: translateY(0) scale(1);
      }
      .input-label {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        left: 10px;
        top: -20px;
        line-height: 12px;
        font-size: 8px;
        color: #fff;
        -webkit-transform: translateY(20px) scale(1.5);
        -ms-transform: translateY(20px) scale(1.5);
        transform: translateY(20px) scale(1.5);
        -webkit-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
      }
      .button {
        transition: background-color 200ms ease-in-out;
        &.login {
          background-color: #6fc051;
          color: #fff;
          &:hover {
            background-color: #55a637;
          }
        }
        &.trial {
          background-color: #3c9dc4;
          color: #fff;
          &:hover {
            background-color: #2d81a3;
          }
        }
        &.register {
          background-color: #ed1b93;
          color: #fff;
          &:hover {
            background-color: #af0a68;
          }
        }
      }
    }
    .login-support {
      position: absolute;
      bottom: 14px;
      width: 100%;
      color: #fff;
      font-size: 13px;
      &.login {
        top: 65px;
        width: 400px;
        right: 0;
      }
      a {
        color: #fff;
      }
    }
    &.verify .inline-input input {
      width: 100px;
    }
    &.trial {
      .button {
        min-width: initial;
        margin-right: 2px;
      }
      &.verify {
        .inline-input input {
          width: 105px;
        }
        .button {
          min-width: initial;
        }
      }
    }
  }
  .header-links {
    float: right;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    width: 400px;
    > * {
      float: left;
      min-width: 80px;
      text-align: center;
    }
    a {
      text-decoration: none;
      color: #fff;
      &.active,
      &:hover {
        color: #3c77d8;
      }
    }
    .button {
      float: right;
    }
  }
}

.content {
  position: relative;
  height: calc(100vh - 96px);
  overflow: hidden;
  .swiper-container {
    width: 100%;
    height: 100%;
    .swiper-slide {
      width: 100%;
      height: 100%;
      .bg {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
      }
    }
  }
  &.game {
    position: relative;
    /*min-height: 100vh;*/
    height: auto;
    background: #3026a7;
  }
  &.service {
    position: relative;
    /*height: 100vh;*/
    height: auto;
    margin-bottom: 200px;
    -webkit-box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
    box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #370165 0%, #270061 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-linear-gradient(315deg, #370165 0%, #270061 100%);
    background: -o-linear-gradient(315deg, #370165 0%, #270061 100%);
    background: linear-gradient(135deg, #370165 0%, #270061 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#370165', endColorstr='#270061',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
  }
  &.mobile {
    position: relative;
    height: auto;
    background: url("../img/password-bg.png") center / cover no-repeat;
    margin-bottom: 200px;
    .mobile-bg {
      height: 700px;
      background: url(../img/mobile.png) center / auto 100% no-repeat;
      margin-bottom: 250px;
      &.wdl {
        background-image: url(../img/mobile_wdl.png);
      }
    }
    .qr-code {
      position: absolute;
      top: 235px;
      &.android {
        left: calc(50% + 85px);
      }
      &.ios {
        left: calc(50% + 275px);
      }
    }
    .android-dl,
    .ios-dl {
      position: absolute;
      left: calc(50% + 85px);
      top: 424px;
      height: 48px;
      width: 150px;
    }
    .ios-dl {
      left: calc(50% + 275px);
    }
  }
  &.promo {
    position: relative;
    height: auto;
    margin-bottom: 200px;
    -webkit-box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
    box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
    background-image: url(../img/promo-bg.png);
    background-color: #caaf96;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
  }
  &.faq {
    position: relative;
    height: auto;
    margin-bottom: 200px;
    -webkit-box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
    box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
    background-image: url(../img/faq-bg.png);
    background-color: #19baee;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
  }
  &.password {
    position: relative;
    height: auto;
    margin-bottom: 200px;
    -webkit-box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
    box-shadow: 0 10px 15px rgba(55, 55, 55, 0.2);
    background-image: url(../img/password-bg.png);
    background-color: #19baee;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    .tab-switch ul li {
      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }
}

.phone-wrapper {
  position: relative;
  height: 380px;
  margin-top: 70px;
  .mobile1 {
    background-image: url(../img/mobile1.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: 400px;
    height: 600px;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
  }
  .mobile1a {
    background-image: url(../img/mobile1a.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: 400px;
    height: 600px;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
  }
  .mobile2 {
    background-image: url(../img/mobile2.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: 400px;
    height: 600px;
    left: 15%;
    top: 60px;
  }
  .mobile3 {
    background-image: url(../img/mobile3.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: 400px;
    height: 600px;
    right: 15%;
    top: 60px;
  }
  .mobile4 {
    background-image: url(../img/mobile4.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: 400px;
    height: 600px;
    left: 3%;
    top: 120px;
  }
  .mobile4a {
    background-image: url(../img/mobile4a.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: 400px;
    height: 600px;
    left: 3%;
    top: 120px;
  }
  .mobile5 {
    background-image: url(../img/mobile5.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: 400px;
    height: 600px;
    right: 3%;
    top: 120px;
  }
  .mickey {
    background-image: url(../img/mickey.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: 250px;
    height: 182px;
    right: 58px;
    top: -40px;
  }
}

.content {
  &.service .wrapper,
  &.mobile .wrapper,
  &.promo .wrapper,
  &.faq .wrapper,
  &.password .wrapper {
    padding-bottom: 375px;
  }
  .image-header {
    display: flex;
    align-items: center;
    margin: 20px 0;
    > img {
      width: 100px;
      margin-right: 4px;
    }
    .text {
      h3 {
        margin: 0;
        font-size: 18px;
      }
      p {
        margin: 0;
        font-size: 14px;
      }
    }
  }
}

.image-title {
  background-repeat: no-repeat;
  background-size: auto 80%;
  background-position: center;
  width: 80%;
  margin: 0 auto;
  height: 180px;
  font-size: 0;
  margin-top: 20px;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 80px;
    height: 80px;
    background-image: url(../img/flare.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 1;
    -webkit-animation: blink 1s infinite alternate;
    animation: blink 1s infinite alternate;
    top: 30px;
    right: 40%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 80px;
    height: 80px;
    background-image: url(../img/flare.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 1;
    -webkit-animation: blink 2s infinite alternate;
    animation: blink 2s infinite alternate;
    top: 15%;
    left: 35%;
  }
  &.mobile {
    background-image: url(../img/mobile-header.png);
  }
  &.promo,
  &.promoa {
    background-image: url(../img/promo-header.png);
  }
  &.faq {
    background-image: url(../img/faq-header.png);
  }
  &.password {
    background-image: url(../img/password-header.png);
  }
  &.register {
    background-image: url(../img/meowmeow.png);
  }
  &.registera {
    background-image: url(../img/register-headera.png);
  }
}

.content-title {
  font-size: 20px;
  letter-spacing: 20px;
  margin: 70px 0 10px;
  text-align: center;
  color: #fff;
  font-weight: normal;
  text-indent: 20px;
}

.content-title-sub {
  color: #fff;
  font-size: 30px;
  letter-spacing: 20px;
  text-indent: 20px;
  margin: 10px auto;
  text-align: center;
  position: relative;
  display: block;
  width: 400px;
  /*font-family: "HeiTi", Arial, sans-serif;*/
  /*font-weight: normal;*/
  /*font-style: normal;*/
}

.content-title.dark {
  color: #727272;
}

.content-title-sub {
  &.dark {
    color: #727272;
    .line {
      border-bottom: 4px solid #727272;
    }
  }
  .line {
    /*position: absolute;*/
    width: 80px;
    margin-top: 20px;
    border-bottom: 4px solid #fff;
    &.left {
      /*left: calc(50% - 100px);*/
      /*transform: translateX(-100px);*/
    }
    &.right {
      /*right: calc(50% - 100px);*/
      /*transform: translateX(100px);*/
    }
  }
}

.banner1 {
  .bg {
    background-image: url(../img/banner/banner_1_bg.png);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  .title {
    background-image: url(../img/banner/banner_1_title.png);
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;
    width: 40%;
    height: 400px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 100px;
    -webkit-animation: bounceInDown 1s linear forwards;
    animation: bounceInDown 1s linear forwards;
  }
  .character {
    background-image: url(../img/banner/banner_1_character.png);
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;
    width: 63%;
    height: 525px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 30vh;
    -webkit-animation: zoomInUp 2s linear forwards;
    animation: zoomInUp 2s linear forwards;
  }
  .deco {
    background-image: url(../img/banner/banner_1_deco.png);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-animation: zoomInAlt 5s linear forwards;
    animation: zoomInAlt 5s linear forwards;
  }
  .button.go {
    cursor: pointer;
    background-image: url(../img/banner/banner_1_btn.png);
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    width: 20%;
    height: 90px;
    position: absolute;
    margin: auto;
    top: 56vh;
    left: 0;
    right: 0;
    -webkit-transition: -webkit-transform 200ms ease-in-out;
    transition: -webkit-transform 200ms ease-in-out;
    -o-transition: transform 200ms ease-in-out;
    transition: transform 200ms ease-in-out;
    transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
    -webkit-animation: pulse 1s infinite alternate;
    animation: pulse 1s infinite alternate;
    &:hover {
      -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  }
}

@media (max-height: 700px) {
  .banner1 {
    .title {
      top: 40px;
    }
    .character {
      width: 50%;
      height: 280px;
      top: 135px;
    }
    .button.go {
      top: 280px;
      width: 180px;
    }
  }
}

@media (max-height: 560px) {
  .banner1 {
    .title {
      top: 4px;
    }
    .character {
      width: 40%;
      height: 280px;
      top: 128px;
    }
    .button.go {
      top: 225px;
    }
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .content {
    height: 550px;
  }
  .banner1 {
    .title {
      top: 40px;
    }
    .character {
      width: 50%;
      height: 280px;
      top: 135px;
    }
    .button.go {
      top: 320px;
    }
  }
}

.banner2 {
  .bg {
    background-image: url(../img/banner/banner_2_bg.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  .title {
    background-image: url(../img/banner/banner_2_title.png);
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;
    width: 35%;
    height: 550px;
    position: absolute;
    margin: auto;
    left: 20%;
    top: 10%;
    -webkit-animation: bounceInLeft 1s linear forwards;
    animation: bounceInLeft 1s linear forwards;
  }
  .titlea {
    background-image: url(../img/banner/banner_2_titlea.png);
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;
    width: 35%;
    height: 550px;
    position: absolute;
    margin: auto;
    left: 20%;
    top: 10%;
    -webkit-animation: bounceInLeft 1s linear forwards;
    animation: bounceInLeft 1s linear forwards;
  }
  .character {
    background-image: url(../img/banner/banner_2_character.png);
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    width: 30%;
    height: 70%;
    position: absolute;
    margin: auto;
    right: 10%;
    top: 8%;
    -webkit-animation: zoomInUp 2s linear forwards;
    animation: zoomInUp 2s linear forwards;
  }
  .deco {
    background-image: url(../img/banner/banner_2_deco.png);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-animation: zoomInAlt 5s linear forwards;
    animation: zoomInAlt 5s linear forwards;
  }
  .button.go:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.banner3 .bg {
  background-image: url(https://imgsg99.com/dsn3/homebanner5.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.banner-addon {
  height: 90px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 45px;
  z-index: 2;
  -webkit-animation: flipInX 1s linear forwards;
  animation: flipInX 1s linear forwards;
  color: #fff;
  .addon-title {
    position: absolute;
    margin: 0;
    font-size: 14px;
    color: #fff;
    top: -66px;
    left: 40px;
  }
  .info p {
    display: inline;
    white-space: nowrap;
    color: #fff;
    margin: 0 20px;
  }
  .info {
    a {
      text-decoration: none;
      color: #fff;
    }
  }
  .wrapper {
    > div {
      &.promo {
        position: relative;
        bottom: 10px;
        height: 90px;
        background: #fff;
      }
      &.info {
        padding-left: 40px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          width: 25px;
          height: 25px;
          background-image: url(../img/icon/message.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          left: 7px;
          top: -2px;
        }
      }
    }
    .col-3 {
      width: calc(30% - 20px);
    }
    > div.promo {
      background: linear-gradient(to bottom, #9f7b2c 0%, #be9331 100%);
      border: 5px solid #fff;
      box-sizing: border-box;
      cursor: default;
      &:nth-child(1) {
        margin-right: 20px;
      }
    }
  }
  .more-addon {
    position: relative;
    height: 100%;
    .swiper-container-phone {
      width: 150px;
      height: 150px;
      overflow: hidden;
      position: absolute;
      bottom: 10px;
      left: 0;
      img {
        position: absolute;
        width: 80%;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
      }
    }
    > img {
      position: absolute;
      right: 45px;
      bottom: 5px;
      z-index: 2;
    }
    .more {
      text-decoration: none;
      color: #fffa54;
      position: absolute;
      top: 10px;
      right: 30%;
      z-index: 3;
    }
  }
  .addon-item {
    float: left;
    width: 33%;
    height: 100%;
    color: #fff;
    position: relative;
    font-size: 13px;
    padding: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    h4 {
      margin: 10px 0;
      color: #fffa54;
    }
    p {
      margin: 0;
    }
    img {
      padding: 5px 10px;
      margin: auto;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

.games-container {
  margin: 50px auto;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .wrapper {
    > div {
      position: relative;
    }
    .right {
      padding-top: 40px;
    }
  }
  .deco {
    background-image: url(../img/deco.png);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-animation: zoomInAlt 5s linear forwards;
    animation: zoomInAlt 5s linear forwards;
  }
  .game-bg {
    width: 500px;
    height: 540px;

    img {
      width: 100%;
    }

    .glare {
      display: block;
      position: absolute;
      width: 120px;
      height: 120px;
      background-image: url(../img/flare.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      opacity: 1;
      &:nth-child(1) {
        top: 163px;
        right: 19px;
        -webkit-animation: blink 3s infinite alternate;
        animation: blink 3s infinite alternate;
      }
      &:nth-child(2) {
        top: -40px;
        right: 75px;
        -webkit-animation: blink 4s infinite alternate;
        animation: blink 4s infinite alternate;
      }
      &:nth-child(3) {
        top: 352px;
        left: 87px;
        -webkit-animation: blink 2s infinite alternate;
        animation: blink 2s infinite alternate;
      }
      &:nth-child(4) {
        top: 394px;
        left: 132px;
        -webkit-animation: blink 1s infinite alternate;
        animation: blink 1s infinite alternate;
      }
    }
  }
  .game-title {
    position: absolute;
    width: 335px;
    height: 300px;
    top: 0;
    left: -20px;
  }
  .current-game {
    width: 400px;
    height: 400px;
    position: absolute;
    top: 115px;
    left: 80px;
    div {
      width: 100%;
      height: 100%;
      background-image: url(../img/game-sprite-x4.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      -webkit-transition: background-position 600ms ease-in-out, -webkit-filter 200ms ease-in-out;
      transition: background-position 600ms ease-in-out, -webkit-filter 200ms ease-in-out;
      -o-transition: background-position 600ms ease-in-out, filter 200ms ease-in-out;
      transition: background-position 600ms ease-in-out, filter 200ms ease-in-out;
      transition: background-position 600ms ease-in-out, filter 200ms ease-in-out, -webkit-filter 200ms ease-in-out;
      &.blur {
        -webkit-filter: blur(10px);
        filter: blur(10px);
      }
      &.pk10 {
        background-position: 0 0;
      }
      &.bjl {
        background-position: 0 -400px;
      }
      &.bjpk10 {
        background-position: 0 -800px;
      }
      &.jsssc {
        background-position: 0 -1200px;
      }
      &.cqssc {
        background-position: 0 -1600px;
      }
      &.auluck5 {
        background-position: 0 -2000px;
      }
      &.hk6 {
        background-position: 0 -2400px;
      }
      &.k3 {
        background-position: 0 -2800px;
      }
      &.gxklsf {
        background-position: 0 -3200px;
      }
      &.gdklsf {
        background-position: 0 -3600px;
      }
      &.gd11x5 {
        background-position: 0 -4000px;
      }
      &.tcpl3 {
        background-position: 0 -4400px;
      }
      &.kl8 {
        background-position: 0 -4800px;
      }
      &.xjssc {
        background-position: 0 -5200px;
      }
      &.tjssc {
        background-position: 0 -5600px;
      }
      &.auluck10 {
        background-position: 0 -6000px;
      }
      &.auluck20 {
        background-position: 0 -6400px;
      }
      &.pcegg {
        background-position: 0 -6800px;
      }
      &.f3d {
        background-position: 0 -7200px;
      }
      &.auluck8 {
        background-position: 0 -7600px;
      }
      &.xync {
        background-position: 0 -8000px;
      }
    }
  }
  .game-title {
    .game-start {
      width: 100%;
      height: 100%;
      background-image: url(../img/game-start.png);
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
    .game-end {
      position: absolute;
      left: 68px;
      top: 78px;
      width: 80%;
      height: 80%;
      background-image: url(../img/game-end.png);
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }
  .button.go {
    width: 110px;
    height: 135px;
    background-image: url(../img/game-btn.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 40px;
    right: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    .play.icon {
      width: 100%;
      height: 100%;
      background-image: url(../img/game-btn-play.png);
      background-position: 55% center;
      background-repeat: no-repeat;
      -webkit-transform: translateZ(20px);
      transform: translateZ(20px);
    }
    &:after {
      content: "";
      width: 135px;
      height: 135px;
      background-image: url(../img/game-btn-glare.png);
      background-size: 100% auto;
      background-repeat: no-repeat;
      position: absolute;
      bottom: -30px;
      right: -60px;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transform: translateZ(20px);
      transform: translateZ(20px);
      -webkit-animation: blink 5s infinite alternate;
      animation: blink 5s infinite alternate;
    }
    a {
      font-size: 0;
      text-decoration: none;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      -webkit-transform: translateZ(40px);
      transform: translateZ(40px);
      &.hide {
        display: none;
      }
    }
  }
  .game-list {
    width: 600px;
    height: auto;
    position: relative;
    &.small {
      width: 450px;
      margin: auto;
    }
  }
  .more-games {
    position: absolute;
    right: 140px;
    top: 0;
    text-decoration: none;
    color: #fffa54;
    .swiper-container {
      width: 600px;
      height: 450px;
    }
  }
  .game-list .game-item {
    float: left;
    height: 150px;
    width: 150px;
    background-image: url(../img/blue-ball.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80% 80%;
    position: relative;
    transition: all 200ms ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    div {
      background-image: url(../img/game-sprite.png);
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 0;
      &.pk10 {
        background-position: 0 0;
      }
      &.bjl {
        background-position: 0 -150px;
      }
      &.bjpk10 {
        background-position: 0 -300px;
      }
      &.jsssc {
        background-position: 0 -450px;
      }
      &.cqssc {
        background-position: 0 -600px;
      }
      &.auluck5 {
        background-position: 0 -750px;
      }
      &.hk6 {
        background-position: 0 -900px;
      }
      &.k3 {
        background-position: 0 -1050px;
      }
      &.gxklsf {
        background-position: 0 -1200px;
      }
      &.gdklsf {
        background-position: 0 -1350px;
      }
      &.gd11x5 {
        background-position: 0 -1500px;
      }
      &.tcpl3 {
        background-position: 0 -1650px;
      }
      &.kl8 {
        background-position: 0 -1800px;
      }
      &.xjssc {
        background-position: 0 -1950px;
      }
      &.tjssc {
        background-position: 0 -2100px;
      }
      &.auluck10 {
        background-position: 0 -2250px;
      }
      &.auluck20 {
        background-position: 0 -2400px;
      }
      &.pcegg {
        background-position: 0 -2550px;
      }
      &.f3d {
        background-position: 0 -2700px;
      }
      &.auluck8 {
        background-position: 0 -2850px;
      }
      &.xync {
        background-position: 0 -3000px;
      }
    }

    a {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1;
    }

    .glare {
      display: block;
      position: absolute;
      width: 90px;
      height: 90px;
      background-image: url(../img/flare3.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      -webkit-animation: blink 1s infinite alternate;
      animation: blink 1s infinite alternate;
      opacity: 0.9;
      z-index: -1;
    }
  }
}

.service {
  list-style: none;
  margin: 0;
  padding: 0;
  &.mobile {
    margin: 20px 0;
  }
  .service-item {
    display: inline-block;
    width: calc(95% / 3);
    text-align: center;
    color: #fff;
  }
  &.mobile .service-item {
    font-style: italic;
    font-size: 21px;
    position: relative;
    text-align: left;
    .coins {
      position: absolute;
      display: block;
      width: 60px;
      height: 60px;
      top: -18px;
      left: 0;
      background-image: url(../img/coin.png);
      background-repeat: no-repeat;
      background-size: contain;
      &:before {
        content: "";
        position: absolute;
        width: 80px;
        height: 80px;
        background-image: url(../img/flare.png);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    &:nth-child(1) .coins:before {
      top: -25px;
      right: -25px;
      -webkit-animation: blink 1s infinite alternate;
      animation: blink 1s infinite alternate;
    }
    &:nth-child(2) .coins:before {
      top: -15px;
      right: -25px;
      -webkit-animation: blink 2s infinite alternate;
      animation: blink 2s infinite alternate;
    }
    &:nth-child(3) .coins:before {
      top: -10px;
      right: -25px;
      -webkit-animation: blink 3s infinite alternate;
      animation: blink 3s infinite alternate;
    }
    h4 {
      font-size: 20px;
      margin: 0;
      padding-left: 60px;
    }
  }
  .service-item {
    h3 {
      color: #ffd34c;
      font-size: 45px;
      margin: 0;
      font-family: "HeiTi", Arial, sans-serif;
      font-weight: normal;
      font-style: normal;
      letter-spacing: 4px;
      span {
        font-weight: bold;
      }
    }
    .deposit {
      height: 256px;
      background-image: url(../img/icon/service1.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    .withdraw {
      height: 256px;
      background-image: url(../img/icon/service2.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    .bank {
      height: 256px;
      background-image: url(../img/icon/service3.png);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.service-footer {
  position: absolute;
  bottom: -40px;
  height: 345px;
  width: 100%;
  background-image: url(../img/wave.png);
  background-size: 100% auto;
  -webkit-animation: loopfooter 120s linear infinite;
  animation: loopfooter 120s linear infinite;
}

.service-wrapper {
  color: #fff;
  height: 66px;
  margin-top: 60px;
  .desc {
    h3,
    h4 {
      margin: 0;
      font-weight: normal;
    }
    h3 {
      font-size: 30px;
      margin-bottom: 10px;
    }
    h4 {
      font-size: 17px;
    }
  }
  .lion {
    background-image: url(../img/icon/service4.png);
    background-repeat: no-repeat;
    background-position: center;
    height: 66px;
  }
  .sun {
    background-image: url(../img/icon/service5.png);
    background-repeat: no-repeat;
    background-position: center;
    height: 66px;
  }
}

.partner {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    flex-grow: 1;
    display: inline-block;
    width: calc(95% / 4);
    height: 100%;
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: center;
    &.suncity {
      background-image: url(http://404ident331.com/);
    }
    &.alipay {
      background-image: url(../img/icon/alipay-icon.png);
    }
    &.wechatpay {
      background-image: url(../img/icon/wechatpay-icon.png);
    }
    &.unionpay {
      background-image: url(../img/icon/unionpay-icon.png);
    }
  }
}

.tab-container {
  width: 100%;
  height: auto;
  min-height: 400px;
  background-color: #fff;
  box-shadow: 0 0 18px #1c9cf0;
  position: relative;
  &.left-panel {
    background-color: #fff;
    margin: auto;
    display: flex;
    align-items: flex-start;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    .tab-switch {
      display: inline-block;
      width: 250px;
      vertical-align: top;
      z-index: 2;
    }
  }
  &.top-panel {
    background-color: #fff;
    padding: 30px;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    .tab-switch {
      display: block;
      margin-bottom: 25px;
    }
  }
  &.left-panel .tab-switch ul {
    display: block;
    li {
      display: block;
      height: 70px;
      background: #3c77d8;
      -webkit-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
      border-bottom: 1px solid #fff;
      &.active {
        background: #fff;
        z-index: 99;
        a {
          color: #3c77d8;
        }
      }
    }
  }

  &.top-panel .tab-switch ul li {
    display: block;
    transition: all 200ms ease-in-out;
  }
  .tab-switch ul {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    li {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 33%;
      position: relative;
      background: #3c77d8;
      &.active {
        font-weight: bold;
      }
      a,
      span {
        text-decoration: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .tab-content {
    padding: 20px 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    p {
      line-height: 1.35rem;
    }
  }
  &.left-panel .tab-content {
    display: inline-block;
    width: calc(100% - 250px);
    vertical-align: top;
    z-index: 1;
    color: #333;
    border-left: 1px solid #3c77d8;
  }
  &.top-panel .tab-content {
    background-color: #4582dd;
    z-index: 1;
    color: #fff;
    padding: 10px 30px;
    margin: auto;
  }
  .tab-content {
    .sub-content {
      display: none;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &.active {
        display: block;
      }
    }
    .sub-content-item {
      height: auto;
      width: 100%;
      .sub-content-item-header {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        font-size: 20px;
        color: #fff;
        cursor: pointer;
        img {
          background-color: #fff;
          width: 100%;
        }
      }
      .sub-content-item-content {
        right: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 20px;
        display: none;
      }
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }
}

.footer {
  height: 200px;
  width: 100%;
  z-index: -1;
  position: fixed;
  bottom: 0;
  background-color: #6d0db1;
  color: #fff;
  text-align: center;
  .first-footer a {
    display: inline-block;
    padding: 0 20px;
    text-decoration: none;
    color: #fff;
    line-height: 100px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -webkit-transition: text-shadow 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
    transition: text-shadow 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
    -o-transition: transform 200ms ease-in-out, text-shadow 200ms ease-in-out;
    transition: transform 200ms ease-in-out, text-shadow 200ms ease-in-out;
    transition: transform 200ms ease-in-out, text-shadow 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
    position: relative;
    &:hover {
      -webkit-transform: translateY(-4px);
      -ms-transform: translateY(-4px);
      transform: translateY(-4px);
      text-shadow: 0 6px 7px rgba(0, 0, 0, 0.2);
    }
  }
  .second-footer {
    position: absolute;
    width: 100%;
    height: 100px;
    background-color: #210f27;
    bottom: 0;
    left: 0;
    right: 0;
    color: #707070;
    p {
      margin: 40px auto;
    }
  }
}

.form-wrapper {
  padding: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.sticky-nav {
  position: fixed;
  right: 10px;
  top: 55%;
  width: 100px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
  -webkit-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  @media screen and (max-width: 1439px) {
    right: 0;
    width: 80px;
  }
  .red-packet {
    background-image: url(../img/sticky-nav-bg.png);
    height: 110px;
    width: 100px;
    border-radius: 7px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 5px 14px rgba(55, 55, 55, 0.6);
    box-shadow: 0 5px 14px rgba(55, 55, 55, 0.6);
    position: relative;
    a {
      font-size: 0;
      text-decoration: none;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../img/icon/sticky-nav-icons/red-packet.png);
    }
  }
  .sticky-nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      width: 50px;
      height: 50px;
      background: linear-gradient(to right bottom, #3100ff, #7600aa);
      border-radius: 7px;
      margin: auto;
      margin-top: 5px;
      -webkit-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
      position: relative;
      -webkit-perspective: 100px;
      perspective: 100px;
    }
    .sticky-hidden-content {
      position: absolute;
      right: 60px;
      top: -15px;
      -webkit-transition: opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
      transition: opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
      -o-transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;
      transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;
      transition: transform 400ms ease-in-out, opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transform: rotateY(-90deg);
      transform: rotateY(-90deg);
      -webkit-transform-origin: right;
      -ms-transform-origin: right;
      transform-origin: right;
      opacity: 0;
      /*  -webkit-box-shadow: 0 0 10px rgba(55,55,55,0.4);
                box-shadow: 0 0 10px rgba(55,55,55,0.4);*/
      p {
        text-align: center;
      }
    }
    .sticky-hidden.kefu {
      position: absolute;
      width: 200px;
      height: 280px;
      right: 60px;
      top: -15px;
      color: #424242;
      background-color: #fff;
      border-radius: 7px;
      -webkit-transition: opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
      transition: opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
      -o-transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;
      transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;
      transition: transform 400ms ease-in-out, opacity 200ms ease-in-out, -webkit-transform 400ms ease-in-out;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transform: rotateY(-90deg);
      transform: rotateY(-90deg);
      -webkit-transform-origin: right;
      -ms-transform-origin: right;
      transform-origin: right;
      opacity: 0;
      -webkit-box-shadow: 0 0 10px rgba(55, 55, 55, 0.4);
      box-shadow: 0 0 10px rgba(55, 55, 55, 0.4);
      &.show {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        opacity: 1;
      }
      button {
        display: block;
        width: 90%;
        margin: 10px auto;
        height: 40px;
        background: #ffd536 url(../img/sticky-nav-bg.png) center;
        color: #424242;
        outline: 0;
        border: 0;
        border-radius: 7px;
        font-size: 17px;
        cursor: pointer;
        position: relative;
        padding-left: 15px;
        &:before {
          content: "";
          position: absolute;
          top: 10px;
          left: 10px;
          width: 20px;
          height: 20px;
          background-image: url(../img/icon/sticky-nav-icons/customer-service.png);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
        &:hover {
          color: #fff;
          background: linear-gradient(to right bottom, #2d00e9, #630090);
        }
      }
      .kefu-mobile-title {
        border-top: 1px solid #ccc;
        text-align: center;
        margin: 12px;
        padding-top: 15px;
        position: relative;
        font-size: 16px;
        &:before {
          content: "或者";
          position: absolute;
          left: 50%;
          top: -8px;
          font-size: 16px;
          line-height: 16px;
          background-color: #fff;
          padding: 0 10px;
          transform: translateX(-50%);
          color: #aaa;
        }
      }
      #mobile-qr {
        width: 150px;
        height: 150px;
        margin: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .sticky-hidden-content.download-instruction {
      background-color: #fff;
      width: 180px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #000;
      border-radius: 6px;
      top: 0;
      box-shadow: 0 0 7px rgba(55, 55, 55, 0.5);
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 16px;
        height: 16px;
        right: -5px;
        background-color: #fff;
      }
    }
    li {
      a {
        font-size: 0;
        text-decoration: none;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.nav a {
        background-image: url(../img/icon/sticky-nav-icons/nav.png);
        background-size: 83%;
      }
      &.qr a {
        background-image: url(../img/icon/sticky-nav-icons/qr.png);
        background-size: 70%;
      }
      &.kefuapp {
        a {
          background-image: url(../img/icon/sticky-nav-icons/kefuapp.png);
          background-size: contain;
        }
      }
      &.qq a {
        background-image: url(../img/icon/sticky-nav-icons/qq.png);
        background-size: 66%;
      }
      &.wechat a {
        background-image: url(../img/icon/sticky-nav-icons/wechat.png);
        background-size: 66%;
      }
      &.cs a {
        background-image: url(../img/icon/sticky-nav-icons/customer-service.png);
        background-size: 90%;
      }
      &.live-chat a {
        background-image: url(../img/icon/sticky-nav-icons/livechat.png);
        background-size: 65%;
      }
      &.download a {
        background-image: url(../img/icon/sticky-nav-icons/download.png);
      }
      &.download-mac a {
        background-image: url(../img/icon/sticky-nav-icons/mac_dl.png);
        background-size: 60%;
      }
      &.download-windows a {
        background-image: url(../img/icon/sticky-nav-icons/windows_dl.png);
        background-size: 60%;
      }
      &.morse a {
        background-image: url(../img/icon/sticky-nav-icons/icon-morse-dark.svg);
      }
      &.back-to-top {
        opacity: 0;
        a {
          background-image: url(../img/icon/sticky-nav-icons/top.png);
        }
      }
      &:hover {
        .sticky-hidden-content {
          -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
          opacity: 1;
        }
      }
    }
  }
}

.form-wrapper {
  .field-set {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    > * {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      vertical-align: middle;
    }
    .field-label {
      color: #fff;
      width: 100px;
      font-size: 14px;
    }
    .field-input {
      color: #999;
      width: 400px;
      height: 40px;
      padding: 0 10px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 14px;
    }
    .verification {
      width: 100px;
      margin-left: 20px;
      background-color: #fff;
    }
    .field-info {
      font-size: 12px;
      width: 265px;
      display: inline-flex;
      align-items: center;
      line-height: initial;
      padding-left: 1.5em;
      text-indent: -1em;
      box-sizing: border-box;
    }

    .field-input.small {
      width: 275px;
    }
    .field-button {
      margin-left: 105px;
      width: 400px;
      outline: none;
      border: 0;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      border-radius: 0;
      cursor: pointer;
      -webkit-transition: -webkit-transform 200ms ease-in-out;
      transition: -webkit-transform 200ms ease-in-out;
      -o-transition: transform 200ms ease-in-out;
      transition: transform 200ms ease-in-out;
      background: #df4949;
      &:active {
        transform: translateY(5px);
      }
    }
  }
  .form-desc {
    text-align: left;
    color: #fff;
    margin: 50px 0 0;
  }
}

@-webkit-keyframes loopfooter {
  from {
    background-position: 1920px 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes loopfooter {
  from {
    background-position: 1920px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(0.3) rotate(0);
    transform: scale(0.3) rotate(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1) rotate(40deg);
    transform: scale(1) rotate(40deg);
  }

  100% {
    opacity: 0.3;
    -webkit-transform: scale(0.2) rotate(80deg);
    transform: scale(0.2) rotate(80deg);
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(0.3) rotate(0);
    transform: scale(0.3) rotate(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1) rotate(40deg);
    transform: scale(1) rotate(40deg);
  }

  100% {
    opacity: 0.3;
    -webkit-transform: scale(0.2) rotate(80deg);
    transform: scale(0.2) rotate(80deg);
  }
}

/*for kefu popup*/

body.kefu-popup {
  margin: 0;
  padding: 0;
  background-color: #329fd9;
}

.kefu-popup {
  iframe {
    width: 100%;
    height: 100%;
    outline: none;
    border: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
  }
  .blur {
    filter: blur(20px);
  }
  .close {
    position: fixed;
    background-color: #329fd9;
    width: 100px;
    height: 30px;
    top: 0;
    right: 0;
    outline: none;
    border: 0;
    color: #fff;
    cursor: pointer;
    animation-delay: 1s;
    text-align: right;
    z-index: 999;
    &:hover {
      color: yellow;
    }
    &.no-font {
      font-size: 0;
    }
  }
  .footer {
    position: fixed;
    width: 200px;
    height: 30px;
    background-color: #329fd9;
    bottom: 0;
    right: 0;
    animation-delay: 1s;
    z-index: 999;
  }
  .instruction {
    display: none;
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;
    width: 80%;
    height: 50px;
    margin: auto;
    &.hidden {
      display: none !important;
    }
    img {
      width: 100%;
      height: auto;
    }
    .close-instruction {
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      height: 100%;
    }
  }
  .overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 55, 55, 0.5);
    z-index: 999;
  }
  .image-container {
    display: none;
    background: url(../img/temp.png) top no-repeat;
    width: 80%;
    height: 500px;
    margin: 40px auto;
    background-size: contain;
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    z-index: 1000;
  }
  .user-agent {
    position: fixed;
    bottom: 100px;
    width: 100%;
    height: 100px;
    left: 0;
  }
}

@media all and (display-mode: standalone) {
  .kefu-popup .instruction {
    display: none !important;
  }
}

.appqr-wrapper {
  width: 145px;
  //height: 195px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding-bottom: 10px;
  .appqrcode {
    width: 100%;
    box-sizing: border-box;
    .qr-code,
    #qr-code {
      width: 110px;
      height: 110px;
      background-color: #fff;
      box-sizing: border-box;
      margin: 10px auto 4px;
    }
  }
  &.kefuapp {
    text-align: center;
    font-size: 0.9rem;
    word-break: break-word;
  }
}

.appqr {
  position: relative;
  width: 100%;
  height: 100%;
  img {
    background-color: #fff;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    top: 0;
    position: absolute;
    left: 0;
  }
}

.appqr-wrapper .text {
  color: #ffbf2f;
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  font-style: italic;
}

.appdlurl {
  color: #7e17b3;
  text-align: center;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.faq {
  .relative {
    position: relative;
  }
  .applogo {
    position: absolute;
    border-radius: 6px;
    padding: 2px;
    &.logo1 {
      left: 12px;
      top: 20px;
      height: 36px;
      width: 36px;
    }
    &.logo2 {
      left: 500px;
      top: 142px;
      height: 17px;
      width: 17px;
    }
    &.logo3 {
      left: 14px;
      top: 25px;
      height: 23px;
      width: 23px;
    }
    &.logo4 {
      left: 505px;
      top: 25px;
      height: 23px;
      width: 23px;
    }
  }
  .webname {
    position: absolute;
    color: #000;
    &.name1 {
      color: #6a6a6a;
      font-size: 0.7rem;
      left: 12px;
      top: 60px;
    }
    &.name2 {
      font-size: 0.8rem;
      left: 525px;
      top: 144px;
    }
    &.name3 {
      left: 44px;
      top: 28px;
    }
    &.name4 {
      left: 535px;
      top: 28px;
    }
  }
}
